@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('./assets/fonts/PPNeueMontreal-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Telegraf';
  src: url('./assets/fonts/PPTelegraf-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Telegraf';
  src: url('./assets/fonts/PPTelegraf-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Telegraf';
  src: url('./assets/fonts/PPTelegraf-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Telegraf';
  src: url('./assets/fonts/PPTelegraf-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titling Gothic FB Compressed';
  src: url('./assets/fonts/Titling-Gothic-FB-Compressed.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titling Gothic FB Compressed';
  src: url('./assets/fonts/Titling-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dedfdf;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
}

.tooltip {
  font-family: Inter, sans-serif !important;
  font-size: 14px !important;
  opacity: 1 !important;
  background-color: #6b7071 !important;
}

.__react_component_tooltip.place-left::after {
  border-left-color: #6b7071 !important;
}

.__react_component_tooltip.place-right::after {
  border-right-color: #6b7071 !important;
}

.__react_component_tooltip.place-top::after {
  border-top-color: #6b7071 !important;
}

.__react_component_tooltip.place-bottom::after {
  border-bottom-color: #6b7071 !important;
}

.custom-table {
  min-width: 640px;
  width: 100%;
}

.custom-table th {
  padding-top: 20px;
  padding-right: 28px;
  padding-bottom: 16px;
  color: #6b7071;
  font-size: 1.125rem;
  font-weight: normal;
  font-family: Inter, sans-serif;
  text-align: left;
  white-space: nowrap;
  position: relative;
}

.custom-table th:not(:first-child) {
  padding-left: 28px;
}

.custom-table thead tr th {
  position: relative;
}

.custom-table thead tr th:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: #a4a7a8;
}

.custom-table tbody tr {
  border-bottom: 1px solid #dedfdf;
}

.without-padding {
  padding: 0 !important;
}

.custom-table td {
  padding-top: 0;
  padding-right: 28px;
  padding-bottom: 0;
  height: 84px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #323839;
  font-size: 1.125rem;
}

.custom-table td:not(:first-child) {
  padding-left: 28px;
}

.custom-table--ppm-history td {
  height: 68px;
  color: #12141a;
}

.custom-table tbody tr td .react-select__control {
  height: 100%;
}

.custom-table > tbody > tr > td:nth-last-child(2),
.custom-table > thead > tr > th:nth-last-child(2) {
  box-shadow: inset -10px 0 10px -8px rgba(50, 56, 57, 0.1);
}

.without-shadow > tbody > tr > td:nth-last-child(2),
.without-shadow > thead > tr > th:nth-last-child(2) {
  box-shadow: none;
}

.ReactModalPortal {
  position: fixed;
  z-index: 100;
}

.table-no-data {
  background: white !important;
}

.table-no-data td {
  padding: 48px 0;
}

.table-no-data td div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayPickerInput {
  width: 100%;
}

#sideMenu {
  transition: 0.5s;
}

.switch {
  top: -1px;
  bottom: -1px;
  left: -1px;
}

.switch.active {
  transition: left 0.3s ease-in-out;
  -webkit-transition: left 0.3s ease-in-out;
}

.switch.inactive {
  transition: right 0.3s ease-in-out;
  -webkit-transition: right 0.3s ease-in-out;
}

input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
  z-index: -1;
}

.fake-slider-thumb {
  margin-top: -6px;
  height: 52px;
  width: 8px;
  background: #12141a;
  cursor: pointer;
}

.fake-right-slider-thumb {
  margin-top: -52px;
  cursor: pointer;
  height: 52px;
  width: 8px;
  background-image: url('assets/images/left-bracket.png');
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
  transform: rotate(180deg);
}

.fake-left-slider-thumb {
  margin-top: -6px;
  cursor: pointer;
  height: 52px;
  width: 8px;
  background-image: url('assets/images/left-bracket.png');
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

/* WebKit/Blink */
.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 52px;
  width: 8px;
  background: #12141a;
  cursor: pointer;
}

.input-min-range-value::-webkit-slider-thumb,
.input-max-range-value::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  height: 52px;
  width: 8px;
  background-image: url('assets/images/left-bracket.png');
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.input-max-range-value::-webkit-slider-thumb {
  transform: rotate(180deg);
}

/* Firefox */
.range-input::-moz-range-thumb {
  height: 52px;
  width: 8px;
  background: #12141a;
  cursor: pointer;
}

.input-min-range-value::-moz-range-thumb,
.input-max-range-value::-moz-range-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  height: 52px;
  width: 8px;
  background-image: url('assets/images/left-bracket.png');
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.input-max-range-value::-moz-range-thumb {
  transform: rotate(180deg);
}

.brainwave_synchrony_score_min_label {
  left: -21px;
}

.hundred_max_label {
  right: -28px;
}

.current_frequency_min_label {
  left: -35px;
}

.current_frequency_max_label {
  right: -42px;
}

.zero_min_label {
  left: -14px;
}

.interference_levels_max_label {
  right: -22px;
}

input::-webkit-credentials-auto-fill-button {
  background-color: #dedfdf;
  cursor: pointer;
}

.password-input::-webkit-credentials-auto-fill-button {
  position: absolute;
  right: 45px;
}

.profile-password-input::-webkit-credentials-auto-fill-button {
  background-color: #a4a7a8;
  position: absolute;
  right: 50px;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

button {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.braincare-report-header {
  background: url('assets/images/braincare-report-heder.png') no-repeat;
  background-size: cover;
}

.transparent {
  background: transparent;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.rc-menu {
  padding: 20px 0;
  border-radius: 8px;
}

.flex-flow-row-wrap {
  flex-flow: row wrap;
}

.move-left {
  transform: translateX(0);
  transition: transform 300ms linear;
}

.move-right {
  transform: translateX(100%);
  transition: transform 300ms linear;
}

.compare-scales {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.disabled-link {
  pointer-events: none;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  margin-left: 10px;
  padding: 6px;
}

/*success*/

.Toastify__toast--success {
  background-color: #f5fff6;
  border-radius: 4px;
  color: #323839;
}

.Toastify__toast--success::before {
  content: url('/src/assets/icons/toastIcons/success.svg');
  position: relative;
  z-index: 100000;
  left: 5px;
  align-self: center;
}

.Toastify__toast--success .Toastify__progress-bar--animated {
  background-color: #5eb250;
}

/*error*/

.Toastify__toast--error {
  background-color: #fff1f2;
  border-radius: 4px;
  color: #323839;
}

.Toastify__toast--error::before {
  content: url('/src/assets/icons/toastIcons/error.svg');
  position: relative;
  z-index: 100000;
  left: 5px;
  align-self: center;
}

.Toastify__toast--error .Toastify__progress-bar--animated {
  background-color: #ea1f4c;
}

.Toastify__close-button {
  display: none;
}

.__react_component_tooltip {
  z-index: 10000 !important;
  border-radius: 8px !important;
  padding: 4px 10px !important;
}

.date-picker {
  font-family: Inter, sans-serif !important;
}

.date-picker .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__time-name,
.react-datepicker__day--keyboard-selected,
.react-datepicker__current-month {
  color: #323839;
}

.date-picker .react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #ffde6e;
}

.date-picker,
.react-datepicker__day:not(.react-datepicker__day--selected, .react-datepicker__day--disabled):hover {
  background-color: #4cd8e8;
}

.date-picker .react-datepicker__day--disabled {
  border-radius: 0.3rem;
  background-color: #dedfdf;
  color: #6b7071;
}

.date-picker .react-datepicker__day--disabled:hover {
  background-color: #dedfdf;
}

.date-picker .react-datepicker__day--keyboard-selected {
  background: none;
}

.date-picker .react-datepicker__navigation-icon {
  top: 6px;
}

.date-picker .react-datepicker__header {
  background-color: #f7f7f7;
}

.date-picker .react-datepicker__triangle:after {
  border-bottom-color: #f7f7f7;
}

.date-view:hover svg {
  fill: #4cd8e8;
}
